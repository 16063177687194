import cx from 'classnames';
import React, { FC, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSessionStorage } from 'usehooks-ts';
import { useAppContext } from '../app-context';
import { AppBar } from '../components/app-bar';
import { AutoBreadcrumb } from '../components/auto-breadcrumb';
import { Sidebar } from '../components/sidebar';
import useIsMobileScreen from '../hooks/use-is-mobile-size';
import usePeriod from '../hooks/use-period';
import MainContent from './main-content';
import PageError from './page-error';
import styles from './page.module.css';

interface Props {
    banner?: React.ReactNode;
    compact?: boolean;
    children: React.ReactNode;
}

export const Page: FC<Props> = (props) => {
    const state = useAppContext();
    const isMobile = useIsMobileScreen();
    const [sidebarPinned, setSidebarPinned] = useSidebarPinned(false);
    const isSuperAdmin = state.person.isSuperAdmin;
    const compact = props.compact ?? false;

    const style = {
        ...(props.banner ? { paddingTop: '1rem' } : undefined),
        ...(compact ? { paddingLeft: '0.5rem', paddingRight: '0.5rem' } : undefined),
    };

    return (
        <div className="wrapper">
            <Sidebar pinned={sidebarPinned} isSuperAdmin={isSuperAdmin} />
            <div className={cx('main', styles.main, { [styles.small]: isMobile, [styles.pinned]: sidebarPinned })}>
                <AppBar onToggleSidebar={() => setSidebarPinned((val) => !val)}></AppBar>
                {props.banner && props.banner}
                <ErrorBoundary FallbackComponent={PageError}>
                    <MainContent className="content" style={style}>
                        <AutoBreadcrumb />
                        {props.children}
                    </MainContent>
                </ErrorBoundary>
            </div>
        </div>
    );
};

const useSidebarPinned = (initialState: boolean) => {
    const isMobile = useIsMobileScreen();

    const [storageSidebarPinned, setStorageSidebarPinned] = useSessionStorage('sidebar-pinned', initialState);
    const [memorySidebarPinned, setMemorySidebarPinned] = useState(initialState);

    return isMobile
        ? ([memorySidebarPinned, setMemorySidebarPinned] as const)
        : ([storageSidebarPinned, setStorageSidebarPinned] as const);
};

type PageWithPeriodProps = {
    component: FC<{ normPeriod: Period }>;
} & Omit<Props, 'children'>;

export const PageWithPeriod: FC<PageWithPeriodProps> = ({ component: Component, ...props }) => {
    const period = usePeriod();

    return period.hasPeriod ? (
        <Page {...props}>
            <Component normPeriod={period.period} />
        </Page>
    ) : (
        period.selector
    );
};
