import cx from 'classnames';
import { FC } from 'react';
import { Button, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../app-context';
import { useClearStore } from '../hooks/use-clear-store';
import { logout } from '../shared/logout';
import usePeriodStore from '../store/norm-period';
import { formatToDate } from '../util/datetime';
import FaIcon from './fa-icon';

import useIsMobileScreen from '../hooks/use-is-mobile-size';
import useMappedNavigation from '../hooks/use-mapped-navigation';
import useNotificationStore from '../store/notification';
import { getEnvironment } from '../util/env';
import './app-bar.css';

interface Props {
    onToggleSidebar: () => void;
}

export function AppBar(props: Props) {
    const isMobileScreen = useIsMobileScreen();
    const { t } = useTranslation();
    const state = useAppContext();
    const { period, setPeriod } = usePeriodStore();
    const clear = useClearStore();
    const { person } = state;
    const navigate = useMappedNavigation();
    const { notifications } = useNotificationStore();

    const env = getEnvironment();

    const getClassNameBanner = (env: string) => {
        return cx('banner', 'banner-' + env);
    };

    function onLogout() {
        logout(state);
        clear();
        navigate('root');
    }

    const showNotificationCount = isMobileScreen && (notifications?.length ?? 0) > 0;

    return (
        <>
            <nav className="navbar navbar-expand navbar-light navbar-bg">
                <a
                    href="#/"
                    className={cx('sidebar-toggle', { 'has-badge': showNotificationCount })}
                    onClick={props.onToggleSidebar}
                >
                    <i className="hamburger align-self-center"></i>
                </a>

                {period && (
                    <Nav>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                setPeriod(undefined);
                                navigate('norm-period-select');
                            }}
                        >
                            {formatToDate(period.start)} - {formatToDate(period.end)}
                        </Button>
                    </Nav>
                )}

                <Nav className="me-auto"></Nav>

                <Nav>
                    <NavDropdown
                        title={
                            <>
                                <i className="fas fa-user"></i>&nbsp; {person.name}
                            </>
                        }
                        align="end"
                    >
                        <InstitutionDropDownItem
                            isSuperAdmin={person.isSuperAdmin === 1}
                            institutionName={state.active.institutionName}
                        />
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => navigate('profile')}>{t('menu.profile')}</NavDropdown.Item>
                        <NavDropdown.Item onClick={onLogout}>{t('menu.logout')}</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </nav>

            {(person.isSuperAdmin === 1 || process.env.REACT_APP_SHOW_ENV_BANNER === '1') && (
                <>
                    <div className={getClassNameBanner(env)}>{env.charAt(0).toUpperCase() + env.slice(1)}</div>
                </>
            )}
        </>
    );
}

const InstitutionDropDownItem: FC<{ isSuperAdmin: boolean; institutionName: string }> = ({
    isSuperAdmin,
    institutionName,
}) => {
    const navigate = useMappedNavigation();

    if (isSuperAdmin) {
        return (
            <NavDropdown.Item onClick={() => navigate('institution-listing')}>
                <FaIcon icon="home" />
                {institutionName}
            </NavDropdown.Item>
        );
    }

    return (
        <NavDropdown.ItemText>
            <FaIcon icon="home" />
            {institutionName}
        </NavDropdown.ItemText>
    );
};
