import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { fetchPeriodEmployees } from '../api/norm-periods';
import { fetchPlanningFigures, fetchZombieShifts, recalculateRemainingInstitutionTimeForAll } from '../api/planning';
import { ZombieShift } from '../api/planning.type';
import { getAllTeamsWithNormWorkersNormTimePrimaryTeam } from '../api/teams';
import { NormTimeCategory } from '../types/norm-time-types';
import { TeamWithWorkers } from '../types/planning-asst';
import { RemoteStatus } from '../types/remote';

interface PlanningState {
    normTimeCategories: NormTimeCategory[] | undefined;
    normWorkersStatus: RemoteStatus;
    normWorkers: NormWorker[] | undefined;
    teamsWithWorkersStatus: RemoteStatus;
    teamsWithWorkers: TeamWithWorkers[] | undefined;
    selectedNormWorker: NormWorker | undefined;
    usagesStatus: RemoteStatus;
    usages: NormTimeUsage[] | undefined;
    figuresStatus: RemoteStatus;
    zombieShiftsStatus: RemoteStatus;
    figures: PlanningFigures | undefined;
    zombieShifts: ZombieShift[] | undefined;
    otherTimeAllocation: number;
    recalculateInstitutionTimeStatus: RemoteStatus;
}

type PlanningStore = PlanningState & {
    fetchTeamsWithWorkers: (jwt: string, period: Period) => Promise<TeamWithWorkers[]>;
    fetchNormWorkers: (jwt: string, period: Period) => Promise<NormWorker[]>;
    getPlanningFigures: (jwt: string, normWorkersSNO: number) => Promise<PlanningFigures>;
    getZombieShifts: (normWorkers: number) => Promise<ZombieShift>;
    clearFigures: () => void;
    setOtherTimeAllocation: (allocation: number) => void;
    recalculateInstitutionTime: (normPeriodsSNO: number) => Promise<void>;
    clear: () => void;
};

const initial: PlanningState = {
    normTimeCategories: undefined,
    normWorkersStatus: 'idle',
    normWorkers: undefined,
    teamsWithWorkersStatus: 'idle',
    teamsWithWorkers: undefined,
    selectedNormWorker: undefined,
    usagesStatus: 'idle',
    usages: undefined,
    figuresStatus: 'idle',
    zombieShiftsStatus: 'idle',
    figures: undefined,
    zombieShifts: undefined,
    otherTimeAllocation: 0,
    recalculateInstitutionTimeStatus: 'idle',
};

const usePlanningStore = create<PlanningStore, [['zustand/immer', never]]>(
    immer((set) => ({
        ...initial,
        fetchNormWorkers: async (jwt: string, period: Period) => {
            set({ normWorkersStatus: 'busy', selectedNormWorker: undefined });

            try {
                const normWorkers = await fetchPeriodEmployees(jwt, period);
                set({ normWorkersStatus: 'idle', normWorkers });
                return normWorkers;
            } catch (e) {
                set({ normWorkersStatus: 'error' });
                throw e;
            }
        },
        fetchTeamsWithWorkers: async (jwt: string, period: Period) => {
            set({ teamsWithWorkersStatus: 'busy' });

            try {
                const allTeams = await getAllTeamsWithNormWorkersNormTimePrimaryTeam(jwt, period.normPeriodsSNO);
                const teamsWithWorkers = allTeams.teamsWithWorkers;
                set({ teamsWithWorkersStatus: 'idle', teamsWithWorkers });
                return allTeams.teamsWithWorkers;
            } catch (e) {
                set({ teamsWithWorkersStatus: 'error' });
                throw e;
            }
        },
        getPlanningFigures: async (jwt: string, normWorkersSNO: number) => {
            const retryFetch = async (attempts = 10): Promise<any> => {
                set({ figuresStatus: 'busy' });
                try {
                    const figures = await fetchPlanningFigures(jwt, normWorkersSNO);

                    if (figures.institutionHoursPerDay.countRemainingInstitutionHours === null && attempts > 0) {
                        await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second
                        return retryFetch(attempts - 1);
                    }
                    set({ figuresStatus: 'idle', figures });
                    return figures;
                } catch (e) {
                    set({ figuresStatus: 'error' });
                    throw e;
                }
            };

            return retryFetch();
        },
        getZombieShifts: async (normWorkersSNO: number) => {
            set({ zombieShiftsStatus: 'busy' });

            try {
                const zombieShifts = await fetchZombieShifts(normWorkersSNO);
                set({ zombieShiftsStatus: 'idle', zombieShifts });
                return zombieShifts;
            } catch (e) {
                set({ zombieShiftsStatus: 'error' });
                throw e;
            }
        },
        clearFigures: () => {
            set({ figures: undefined });
        },
        setOtherTimeAllocation: (allocation: number) => {
            set({ otherTimeAllocation: allocation });
        },
        recalculateInstitutionTime: async (normPeriodsSNO) => {
            set({ recalculateInstitutionTimeStatus: 'busy' });

            try {
                await recalculateRemainingInstitutionTimeForAll(normPeriodsSNO);
                set({ recalculateInstitutionTimeStatus: 'idle' });
            } catch (e) {
                set({ recalculateInstitutionTimeStatus: 'error' });
            }
        },
        clear: () => {
            set(initial);
        },
    }))
);

export default usePlanningStore;
