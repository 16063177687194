import React from 'react';
import { BrowserRouter, Outlet, Route, Routes as RoutesContainer } from 'react-router-dom';
import packageJson from '../package.json';
import { getLocaleFromLanguage, setLanguage } from './api/i18n';
import LazyLoadingFallback from './components/lazy-loading-fallback';
import OutletPassThrough from './components/outlet-pass-through';
import { Page, PageWithPeriod } from './components/page';
import { Protected } from './components/protected';
import ShiftCloningContext from './components/shift-cloning/context';
import { setJwt } from './jwt';
import { LoginPage } from './login/login-page';
import SupportStart from './support/start';
import { setEnvironment } from './util/env';
import PlanningContext from './workplan/workplan-context';

const SuperAdminToolsPage = React.lazy(() => import('./super-admin-tools/page'));
const NormTimeTypeRolloutPage = React.lazy(() => import('./norm-day-type-rollout/norm-day-type-rollout-page'));
const NormMeetingsListingPage = React.lazy(() => import('./norm-meetings/norm-meetings-listing-page'));
const NormMeetingPage = React.lazy(() => import('./norm-meetings/norm-meeting-page'));
const NormMeetingsRolloutPage = React.lazy(() => import('./norm-meetings/norm-meetings-rollout-page'));
const NormMeetingsCreatePage = React.lazy(() => import('./norm-meetings/norm-meetings-create-page'));
const NormMeetingsCreateAddDatesPage = React.lazy(() => import('./norm-meetings/norm-meetings-add-dates-page'));
const NormMeetingsEditPage = React.lazy(() => import('./norm-meetings/norm-meetings-edit-page'));
const BeLogsPage = React.lazy(() => import('./be-logs/page'));
const BeLogDetailsPage = React.lazy(() => import('./be-logs/details/page'));
const BeLogDetails = React.lazy(() => import('./be-logs/details'));
const SetPasswordPage = React.lazy(() => import('./set-password/set-password-page'));
const TeamsPage = React.lazy(() => import('./norm-teams/teams-page'));
const CreateInstitutionPage = React.lazy(() => import('./create-institution/create-institution-page'));
const ProfilePage = React.lazy(() => import('./profile/profile-page'));
const EmployeesPage = React.lazy(() => import('./employees/employees-page'));
const EmployeesCreatePage = React.lazy(() => import('./employees/create-employee-page'));
const EmployeesEditPage = React.lazy(() => import('./employees/edit-employee-page'));
const PeriodsPage = React.lazy(() => import('./norm-periods/periods-page'));
const NewPeriodPage = React.lazy(() => import('./norm-periods/new-period-page'));
const NewPeriodPageClone = React.lazy(() => import('./norm-periods/new-period-page-clone'));
const SelectNormPeriodPage = React.lazy(() => import('./norm-periods/select-page'));
const AgreementsPage = React.lazy(() => import('./agreements/agreements-page'));
const Workplan = React.lazy(() => import('./workplan/edit/workplan'));
const WorkplanGanttTimeline = React.lazy(() => import('./workplan/gantt/timeline'));
const NormTimeTypesPage = React.lazy(() => import('./norm-time-types/norm-time-types-page'));
const NormTimeTypeEditPage = React.lazy(() => import('./norm-time-types/time-type-edit-page'));
const NormTimeTypeCreatePage = React.lazy(() => import('./norm-time-types/time-type-create-page'));
const PlanningOverviewPage = React.lazy(() => import('./planning/planning-overview-page'));
const PlanningWorkerPage = React.lazy(() => import('./planning/planning-worker/page'));
const PlanningWorkerSubstitutePage = React.lazy(() => import('./planning/planning-worker-substitute/page'));
const InstitutionPage = React.lazy(() => import('./institution/page'));
const NewInstitutionPage = React.lazy(() => import('./institution/page-new'));
const CalendarPage = React.lazy(() => import('./calendar/calendar-page'));
const PlanningAsstOverviewPage = React.lazy(() => import('./planning-assistant/overview-page'));
const PlanningAsstTemplateSelectedPage = React.lazy(() => import('./planning-assistant/template-selected'));
const PlanningAsstDefaultTemplateView = React.lazy(() => import('./planning-assistant/default-template-view'));
const PlanningAsstWeekSelectedPage = React.lazy(() => import('./planning-assistant/week-selected'));
const PlanningAsstRollout = React.lazy(() => import('./planning-assistant/rollout/rollout'));
const NormWorkerPage = React.lazy(() => import('./norm-workers/page'));
const NormWorkerEditPage = React.lazy(() => import('./norm-workers/edit-employee-page'));
const NormWorkerNewPage = React.lazy(() => import('./norm-workers/add-employee-page'));
const LabelsPage = React.lazy(() => import('./labels/labels-page'));
const SettingsPage = React.lazy(() => import('./settings/page'));
const EmailLogPage = React.lazy(() => import('./email-log/email-log-page'));
const ChangelogPage = React.lazy(() => import('./changelog/changelog-page'));
const ChangelogCreatePage = React.lazy(() => import('./changelog/changelog-create-page'));
const LockedUsersPage = React.lazy(() => import('./locked-users/locked-users-page'));
const InstitutionEditPage = React.lazy(() => import('./institution/institution-edit'));
const InstitutionSelectedPage = React.lazy(() => import('./institution/institution-selected'));
const InstitutionUserListing = React.lazy(() => import('./institution/user/listing'));
const InstitutionUserSelectedPage = React.lazy(() => import('./institution/user/user-selected'));
const InstitutionUserResetPassword = React.lazy(() => import('./institution/user/reset-password'));
const LandingPage = React.lazy(() => import('./landing-page/landing-page'));
const InstitutionsOverviewPage = React.lazy(() => import('./institutions-overview/institutions-overview'));
const InstitutionsOverviewEditPage = React.lazy(() => import('./institutions-overview/institution-edit-page'));
const TimeOverviewPage = React.lazy(() => import('./time-overview/time-overview'));
const JsErrorLogsPage = React.lazy(() => import('./js-error-logs'));
const ApplicationLogsPage = React.lazy(() => import('./logging/page'));
const RequestLogsPage = React.lazy(() => import('./request-logs/page'));
const RequestLogDetailsPage = React.lazy(() => import('./request-logs/details/page'));
const RequestLogDetails = React.lazy(() => import('./request-logs/details'));
const TilsynOverviewPage = React.lazy(() => import('./tilsyn-overview/tilsyn-overview-page'));
const InstitutionAccessPage = React.lazy(() => import('./institution-access/institution-access-page'));
const NotificationsPage = React.lazy(() => import('./notifications/notifications-page'));
const WorkplanPrintSettingsPage = React.lazy(() => import('./workplan/print/print-settings-page'));
const QuickstartPage = React.lazy(() => import('./quickstart-guide/quickstart-page'));
const QuickstartEditPage = React.lazy(() => import('./quickstart-guide/quickstart-edit-page'));
const WorkSchedule = React.lazy(() => import('./work-schedule/work-schedule'));
const ShiftAlterationListing = React.lazy(() => import('./shift-alteration/request-listing'));
const ShiftAlterationHistory = React.lazy(() => import('./shift-alteration/request-history'));
const ShiftAlterationRequest = React.lazy(() => import('./shift-alteration/request'));
const ShiftAlterationApproval = React.lazy(() => import('./shift-alteration/approval-listing'));
const ShiftAlterationApprovalHistory = React.lazy(() => import('./shift-alteration/approval-history'));
const WorkplanPrintNormWorkerSettingsPage = React.lazy(
    () => import('./workplan/print-normworker/print-normworker-settings-page')
);
const ManningPage = React.lazy(() => import('./workplan/manning/manning-page'));
const DatesListingPage = React.lazy(() => import('./dates/dates-listing-page'));
const DatesCreatePage = React.lazy(() => import('./dates/dates-create-page'));
const DatesEditPage = React.lazy(() => import('./dates/dates-edit-page'));
const DateFormatPage = React.lazy(() => import('./developer-dateformat/page'));
const ConversationsPage = React.lazy(() => import('./conversations/page'));
const NewConversationPage = React.lazy(() => import('./conversations/new/new-conversation'));
const TimeUsageOverview = React.lazy(() => import('./time-usage-overview'));
const BonusOverview = React.lazy(() => import('./bonus-overview'));
const BonusOverviewOwn = React.lazy(() => import('./bonus-overview-own'));
const DebugWorkingHours = React.lazy(() => import('./developer-working-hours/page'));

interface Props {
    state: AppState;
    setState: React.Dispatch<React.SetStateAction<AppState>>;
}

export function Routes(props: Props) {
    const { state, setState } = props;

    // @TODO
    //   @Stephen
    //   - This method definitely does not belong here :-)
    //     It would be better suited in a JWT util class
    function setLogon(jwt: string, payload: LoginPayload) {
        const person = { ...payload.data.person, locale: getLocaleFromLanguage(payload.data.person.language) };
        const env = payload.data.env.name;
        //const active = payload.data.active!;

        const activeInstitution = payload.data.institutions.find((r) => Boolean(r.isActive));

        // @TODO
        //   - This 'active' stuff needs some serious untangling
        const active = {
            institutionsSNO: 0,
            institutionName: '',
            employeesSNO: 0,
            employeeRole: 'employee',
            isMiniManager: 0,
        };

        if (activeInstitution) {
            active.employeeRole = activeInstitution.employeeRole;
            active.employeesSNO = activeInstitution.employeesSNO;

            active.institutionName = activeInstitution.institutionName;
            active.institutionsSNO = activeInstitution.institutionsSNO;

            active.isMiniManager = activeInstitution.isMiniManager;
        }

        const normPeriodMetadata = payload.data.normPeriod;

        localStorage.setItem('jwt', jwt);
        localStorage.setItem('person', JSON.stringify(person));
        localStorage.setItem('active', JSON.stringify(active));
        localStorage.setItem('env', env);

        if (activeInstitution) {
            localStorage.setItem('institution', JSON.stringify(activeInstitution));
        }

        setEnvironment(payload.data.env.name);
        setState({ ...state, jwt, person, active, institution: activeInstitution, normPeriodMetadata });
        setJwt(jwt);

        if (person.language) {
            setLanguage(person.language);
        }
    }

    return (
        <BrowserRouter basename={packageJson.homepage}>
            <RoutesContainer>
                <Route path="/" element={<LoginPage setLogon={setLogon} />} />
                <Route path="/support/start">
                    <Route index element={<SupportStart />} />
                    <Route path=":tokenFromUser" element={<SupportStart />} />
                </Route>
                <Route
                    path="/set-password"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <SetPasswordPage />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/opret-institution"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <CreateInstitutionPage />
                        </React.Suspense>
                    }
                />

                <Route
                    path="/super-admin/institution"
                    element={
                        <Protected>
                            <Outlet />
                        </Protected>
                    }
                >
                    <Route
                        index
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <InstitutionPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="ny"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NewInstitutionPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="rediger/:institutionsSNO"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <InstitutionEditPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path=":institutionsSNO"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <InstitutionSelectedPage />
                            </React.Suspense>
                        }
                    >
                        <Route
                            path="rediger"
                            element={
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <InstitutionEditPage />
                                </React.Suspense>
                            }
                        />
                        <Route path="users" element={<OutletPassThrough />}>
                            <Route index element={<InstitutionUserListing />} />
                            <Route
                                path=":usersSNO"
                                element={
                                    <React.Suspense fallback={<LazyLoadingFallback />}>
                                        <InstitutionUserSelectedPage />
                                    </React.Suspense>
                                }
                            >
                                <Route
                                    path="reset-password"
                                    element={
                                        <React.Suspense fallback={<LazyLoadingFallback />}>
                                            <InstitutionUserResetPassword />
                                        </React.Suspense>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route
                    path="/notifikationer"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NotificationsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/profil"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ProfilePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/start"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <LandingPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/teams"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <TeamsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsCreatePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/add-dates/:normMeetingsSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsCreateAddDatesPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/rollout/:normMeetingsSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsRolloutPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/edit/:normMeetingsSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsListingPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/changelog"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ChangelogPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/labels"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <LabelsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/dates"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <DatesListingPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/dates/create"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <DatesCreatePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/dates/edit/:datesSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <DatesEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/emaillog"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmailLogPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/lockedUsers"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <LockedUsersPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/institutionsoversigt"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <InstitutionsOverviewPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/institutionsoversigt/rediger"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <InstitutionsOverviewEditPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/institutionsadgang/:institutionsSNO"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <InstitutionAccessPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/ansatte"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmployeesPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/ansatte/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmployeesCreatePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/ansatte/edit/:employeesSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmployeesEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PeriodsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normtidstyper/udrulning"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormTimeTypeRolloutPage />
                            </React.Suspense>
                        </Protected>
                    }
                ></Route>
                <Route
                    path="/normarbejder"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormWorkerPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normarbejder/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormWorkerNewPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normarbejder/edit/:normWorkersSNOSrc"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormWorkerEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NewPeriodPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder/klon/:normPeriodsSNOSrc"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NewPeriodPageClone />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder/vaelg"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <SelectNormPeriodPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/overenskomster"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <AgreementsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/tidsoversigt"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PageWithPeriod component={TimeOverviewPage} />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/dateformats"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <DateFormatPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/working-hours"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <DebugWorkingHours />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/hurtigstart-guide"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <QuickstartPage />
                            </React.Suspense>
                        </Protected>
                    }
                />

                <Route
                    path="/notifikationer"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NotificationsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/profil"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ProfilePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/start"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <LandingPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/teams"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <TeamsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsCreatePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/edit/:normMeetingsSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder/:normMeetingsSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/norm-moeder"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormMeetingsListingPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/changelog"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ChangelogPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/hurtigstart-guide-rediger"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <QuickstartEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/labels"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <LabelsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/emaillog"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmailLogPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/lockedUsers"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <LockedUsersPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/institutionsoversigt"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <InstitutionsOverviewPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/institutionsoversigt/rediger"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <InstitutionsOverviewEditPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/institutionsadgang/:institutionsSNO"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <InstitutionAccessPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/ansatte"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmployeesPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/ansatte/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmployeesCreatePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/ansatte/edit/:employeesSNO"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <EmployeesEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PeriodsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normtidstyper/udrulning"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormTimeTypeRolloutPage />
                            </React.Suspense>
                        </Protected>
                    }
                ></Route>
                <Route
                    path="/normarbejder"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormWorkerPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normarbejder/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormWorkerNewPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normarbejder/edit/:normWorkersSNOSrc"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormWorkerEditPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NewPeriodPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder/klon/:normPeriodsSNOSrc"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NewPeriodPageClone />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/normperioder/vaelg"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <SelectNormPeriodPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/overenskomster"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <AgreementsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />

                <Route
                    path="/workplan"
                    element={
                        <Protected>
                            <PlanningContext>
                                <Outlet />
                            </PlanningContext>
                        </Protected>
                    }
                >
                    <Route
                        index
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ShiftCloningContext>
                                    <PageWithPeriod component={Workplan} />
                                </ShiftCloningContext>
                            </React.Suspense>
                        }
                    />
                </Route>

                <Route
                    path="/workplan-gantt"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningContext>
                                    <PageWithPeriod component={WorkplanGanttTimeline} compact />
                                </PlanningContext>
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/bemanding"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningContext>
                                    <ManningPage />
                                </PlanningContext>
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/workplan-print"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningContext>
                                    <WorkplanPrintSettingsPage />
                                </PlanningContext>
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/moedeplan-medarbejder-print/"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningContext>
                                    <WorkplanPrintNormWorkerSettingsPage />
                                </PlanningContext>
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/work-schedule"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PageWithPeriod component={WorkSchedule} />
                            </React.Suspense>
                        </Protected>
                    }
                />

                <Route path="/vagt-oensker">
                    <Route
                        index
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationListing} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path="historik"
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationHistory} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path="ny"
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationRequest} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path="rediger"
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationRequest} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                </Route>

                <Route path="/vagt-oensker-godkendelse">
                    <Route
                        index
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationApproval} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path="ny"
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationRequest} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                </Route>

                <Route path="/vagt-oensker-godkendelse-historik">
                    <Route
                        index
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={ShiftAlterationApprovalHistory} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                </Route>
                <Route
                    path="/tidstyper"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <NormTimeTypesPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="tidstyper/:normTimeTypesSNO"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <NormTimeTypeEditPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="tidstyper/ny"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <NormTimeTypeCreatePage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/tilsyn-oversigt"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <TilsynOverviewPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/planlaegning/*"
                    element={
                        <Protected>
                            <Outlet />
                        </Protected>
                    }
                >
                    <Route
                        index
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningOverviewPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path=":normWorkerId"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningWorkerPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="vikarer/:normWorkerId"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningWorkerSubstitutePage />
                            </React.Suspense>
                        }
                    />
                </Route>
                <Route
                    path="/kalender"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <CalendarPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/planlaegningsassistent"
                    element={
                        <Protected>
                            <Outlet />
                        </Protected>
                    }
                >
                    <Route
                        index
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PlanningAsstOverviewPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path=":templateId"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <Page>
                                    <PlanningAsstTemplateSelectedPage />
                                </Page>
                            </React.Suspense>
                        }
                    >
                        <Route
                            index
                            element={
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PlanningAsstDefaultTemplateView />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="uge/:weekId"
                            element={
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <ShiftCloningContext>
                                        <PlanningAsstWeekSelectedPage />
                                    </ShiftCloningContext>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="rollout"
                            element={
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PlanningAsstRollout />
                                </React.Suspense>
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path="/super-admin/changelog/ny"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ChangelogCreatePage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/js-logs"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <JsErrorLogsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/tools"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <SuperAdminToolsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/fe-logs"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <ApplicationLogsPage />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/super-admin/be-logs"
                    element={
                        <Protected>
                            <Outlet />
                        </Protected>
                    }
                >
                    <Route
                        index
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <BeLogsPage />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path=":requestId"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <BeLogDetailsPage />
                            </React.Suspense>
                        }
                    >
                        <Route index element={<BeLogDetails />} />
                    </Route>
                </Route>
                <Route
                    path="/super-admin/request-logs"
                    element={
                        <Protected>
                            <Outlet />
                        </Protected>
                    }
                >
                    <Route
                        index
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <RequestLogsPage />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path=":requestId"
                        element={
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <RequestLogDetailsPage />
                            </React.Suspense>
                        }
                    >
                        <Route index element={<RequestLogDetails />} />
                    </Route>
                </Route>
                <Route
                    path="/indstillinger"
                    element={
                        <React.Suspense fallback={<LazyLoadingFallback />}>
                            <SettingsPage />
                        </React.Suspense>
                    }
                />
                <Route path="/samtaler">
                    <Route
                        index
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <Page>
                                        <ConversationsPage />
                                    </Page>
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path="ny"
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <PageWithPeriod component={NewConversationPage} />
                                </React.Suspense>
                            </Protected>
                        }
                    />
                    <Route
                        path=":conversationId"
                        element={
                            <Protected>
                                <React.Suspense fallback={<LazyLoadingFallback />}>
                                    <Page>
                                        <ConversationsPage />
                                    </Page>
                                </React.Suspense>
                            </Protected>
                        }
                    />
                </Route>
                <Route
                    path="/tidsforbrug-oversigt"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PageWithPeriod component={TimeUsageOverview} />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/bonus-oversigt"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PageWithPeriod component={BonusOverview} />
                            </React.Suspense>
                        </Protected>
                    }
                />
                <Route
                    path="/min-bonus-oversigt"
                    element={
                        <Protected>
                            <React.Suspense fallback={<LazyLoadingFallback />}>
                                <PageWithPeriod component={BonusOverviewOwn} />
                            </React.Suspense>
                        </Protected>
                    }
                />
            </RoutesContainer>
        </BrowserRouter>
    );
}
