import React from 'react';
import { useTranslation } from 'react-i18next';
import { chooseInstitution } from '../api/person';
import { Content } from '../components/content';

interface InstitutionSelectorProps {
    jwt: string;
    person: LoginPerson;
    institutions: Institution[];
    onInstitutionSelected: (jwt: string, payload: LoginPayload, institution: Institution) => Promise<void>;
}

export function InstitutionSelector(props: InstitutionSelectorProps) {
    const { t } = useTranslation();

    async function selectInstitution(institution: Institution) {
        const payload = await chooseInstitution(props.jwt, institution.institutionsSNO, institution.employeesSNO);

        await props.onInstitutionSelected(payload.JWT, payload, institution);
    }

    async function onSelectInstitution(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, institution: Institution) {
        e.preventDefault();
        await selectInstitution(institution);
    }

    if (props.institutions.length === 1) {
        return <></>;
    }

    return (
        <>
            <h2>{t('select-institution.header')}</h2>
            {props.institutions.map((institution) => (
                <Content key={'institution' + institution.institutionsSNO}>
                    <a href="#/" onClick={(e) => onSelectInstitution(e, institution)}>
                        <i className="fa fa-home"></i> {institution.institutionName} (
                        {t('institution-access.role.' + institution.employeeRole)})
                    </a>
                </Content>
            ))}
        </>
    );
}
