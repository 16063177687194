import { useWindowSize } from 'usehooks-ts';

const MOBILE_SCREEN_WIDTH = 768;

const useIsMobileScreen = () => {
    const size = useWindowSize();
    return size.width < MOBILE_SCREEN_WIDTH;
};

export default useIsMobileScreen;
