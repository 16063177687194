import { formatToIsoDate } from '../util/datetime';
import { hashPassword } from '../util/password';
import { PersonMergeInfo } from './person.type';
import { request } from './request';

export async function login(email: string, password: string): Promise<LoginResponse> {
    const env = process.env;
    const url = `${env.REACT_APP_API_HOST}/log-in`;
    const body = JSON.stringify({ email, password });
    const options = {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
    };
    const result = await fetch(url, options);
    return await result.json();
}

export async function createInstitutionAndManager(
    email: string,
    password: string,
    institutionName: string,
    managerName: string
): Promise<CreateInstitutionResponse> {
    const env = process.env;
    const url = `${env.REACT_APP_API_HOST}/institution-and-manager-create`;
    const body = JSON.stringify({
        email,
        institutionName,
        managerName,
        password,
    });
    const options = {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
    };
    const result = await fetch(url, options);
    return await result.json();
}

export async function fetchPerson(jwt: string, personsSNO: number): Promise<Person> {
    const env = process.env;
    const url = `${env.REACT_APP_API_HOST}/person-get`;
    const body = JSON.stringify({ personsSNO });
    const options = {
        method: 'POST',
        body,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
        },
    };
    const result = await fetch(url, options);
    const response = await result.json();
    if (!response.isSuccess) {
        throw new Error('Could not load person: ' + personsSNO);
    }
    return response.payload;
}

export async function chooseInstitution(
    jwt: string,
    institutionsSNO: number,
    employeesSNO: number
): Promise<LoginPayload> {
    const env = process.env;
    const url = `${env.REACT_APP_API_HOST}/institution-choose`;
    const body = JSON.stringify({ institutionsSNO, employeesSNO });
    const options = {
        method: 'POST',
        body,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
        },
    };
    const result = await fetch(url, options);
    const response = await result.json();
    return response.payload;
}

export async function savePersonName(jwt: string, personsSNO: number, name: string) {
    await request(jwt, 'person-set-name', { personsSNO, name });
}

export async function savePersonShortName(jwt: string, personsSNO: number, shortName: string) {
    await request(jwt, 'person-set-short-name', { personsSNO, shortName });
}

export async function savePersonMobileNo(jwt: string, personsSNO: number, mobileNo: string) {
    await request(jwt, 'person-set-mobile-no', { personsSNO, mobileNo });
}

export async function savePersonDob(jwt: string, personsSNO: number, dob: Date) {
    await request(jwt, 'person-set-birthday', { personsSNO, birthday: formatToIsoDate(dob) });
}

export async function savePersonLanguage(jwt: string, personsSNO: number, language: string) {
    await request(jwt, 'person-set-language', { personsSNO, language: language?.toUpperCase() });
}

export async function sendForgotPasswordEmail(email: string) {
    const env = process.env;
    const url = `${env.REACT_APP_API_HOST}/person-send-forgot-password-email`;
    const body = JSON.stringify({ email });
    const options = {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
    };
    await fetch(url, options);
}

export async function setPersonPassword(email: string, token: string, password: string): Promise<string> {
    const env = process.env;
    const url = `${env.REACT_APP_API_HOST}/person-set-password`;
    const body = JSON.stringify({ email, token, password });
    const options = {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
    };
    const result = await fetch(url, options);
    const response = await result.json();
    if (!response.isSuccess) {
        return response.responseCode;
    }
    return '';
}

export async function setPersonOneTimePassword(jwt: string, email: string, password: string): Promise<void> {
    return await request(jwt, 'person-set-password-otp', { email, password: hashPassword(email, password) });
}

export async function setPersonPasswordOtpToReal(jwt: string, hashedPassword: string): Promise<LoginPayload> {
    const { JWT, jwtPayload } = await request(jwt, 'person-set-password-otp-to-real', { password: hashedPassword });

    return {
        JWT,
        data: jwtPayload,
    };
}

export async function setPersonEmail(jwt: string, email: string, personsSNO: number) {
    const endpoint = 'person-set-email';
    return await request(jwt, endpoint, { email, personsSNO });
}

export async function getPersonFromEmail(jwt: string, email: string) {
    const endpoint = 'person-get-from-email';
    return await request(jwt, endpoint, { email });
}

export async function sendWelcomeMail(jwt: string, email: string, personsSNO: number) {
    const endpoint = 'person-send-welcome-email';
    return await request(jwt, endpoint, { email, personsSNO });
}

export async function getPersonMergeInfoFromEmail(
    jwt: string,
    personsSNO: number,
    email: string
): Promise<PersonMergeInfo> {
    return await request(jwt, 'persons-get-merge-info', { personsSNO, email });
}

export async function setPersonMerge(jwt: string, personsSNOSrc: number, personsSNODst: number) {
    return await request(jwt, 'persons-merge', { personsSNOSrc, personsSNODst });
}
